import React from 'react'
import './workscontent.css';
import wrkimg1 from '../../assets/Frame 64.png'
import wrkimg2 from '../../assets/Frame 66.png'
const WorksContent = () => {
  return (
    <div className="workscontent">
        <div className="container">
        <div>
        <h3 className='mainheadser'>Works</h3>
        <h3 className='sndheadserv'>Delve into our Vibrant Portfolio of dazzling solutions.</h3>
        </div>   
        <div className='imgcontent'>
        <div>
            <div className="imgplace"> <img src={wrkimg1} alt="" /></div>
            <div className="imgdesc">
                <h4>Fashion</h4>
                <span>Denzo: Shaping leading Fashion brand through branding.</span>
            </div>
        </div>


        <div>
            <div className="imgplace"> <img src={wrkimg2} alt="" /></div>
            <div className="imgdesc">
                <h4>Electronics</h4>
                <span>Urban: New Digital identity to the leading electronic manufacturers.</span>
            </div>
        </div>



        <div>
            <div className="imgplace"> <img src={wrkimg1} alt="" /></div>
            <div className="imgdesc">
                <h4>Information Technology</h4>
                <span>Artek: New identity to the leading IT Company.</span>
            </div>
        </div>



   
     </div>
        </div>
    </div>
  )
}

export default WorksContent