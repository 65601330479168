
import React from 'react'
// import './mainservicehero.css';
import brandinghero1 from '../../assets/brandinghero1.png'

import data6 from '../../assets/dataslider6.png'



import data3 from '../../assets/datahero2.png'
import data1 from '../../assets/datahero1.png'
// import brandinghero4 from '../../assets/bra'
import msh1 from '../../assets/msh1.png'

import { FaRegArrowAltCircleRight } from "react-icons/fa";
import './data.css'

import DataAnalyticsCarousel from './DataAnalyticsCarousel'
import { Link } from 'react-router-dom'
const DataAnalyticsHero = () => {
  return (
    <div className="mainservicehero">
    <div className="Web">
        <div className="msh">
        <div className='msleft'>
            <div className="msleftfirst">
                <img  src={msh1} alt="" />
                <div className="overlay">
    <h1>Data analytics and Consulting</h1>
    {/* <p>We provide unique identity to differentiate and build customer loyalty</p> */}
</div>
            </div>
            <div className="msleftsecond">
                {/* <img src={msh2} alt="" />
                
                <img src={msh3} alt="" /> */}
                 <div className="image-container">
    <img src={data3} alt="" />
    <div className="overlay1">
        <h3>Industry Intelligence</h3>
    
    </div>
</div>
<div className="image-container">
    <img src={data1} alt="" />
    <div className="overlay1">
        <h3>Investment analysis</h3>
   
    </div>
</div>
            </div>
         </div>
         <div className="msright">
<div className="image-container">
    <img src={data6} alt="" />
    <div className="overlay2">
        <h1  style={{color:'white'}}>Predictive analytics</h1>
       
        <Link to="/contact" className="overlay2btn">
    Let's talk <FaRegArrowAltCircleRight className="btnicn" />
  </Link>
    </div>
</div>
</div>
        </div>
   
    </div>
    <div className="container">
        <h1 style={{fontWeight:600}}>Data Analytics & Consulting  <span style={{color:'grey'}} >Services</span></h1>
    
    </div>
   <DataAnalyticsCarousel/>

    <div className="branding-strategy">
            <div className="intro-section-data">
                <h1>Market Trend Forecasting</h1>
                <p>
                Our models empower businesses to anticipate market dynamics, facilitating proactive decision-making and strategy development. With our accurate forecasts, you can stay ahead of the competition, identify emerging trends, and seize new opportunities.

                </p>
            </div>
            <div className="steps-grid">
                <div className="step-box client">
                    <h2>Advanced ML and Deep Learning Algorithms</h2>
                    <p>
                     By thoroughly understanding your vision and mission, we ensure that your brand message is clear and compelling. A well-defined vision helps build trust and credibility with your audience, making them more likely to choose your brand.
                    </p>
                </div>
                <div className="step-box datasecond">
                    <h2>Customer Behavior Analysis</h2>
                    <p>
                    Understanding customer preferences and behaviors is crucial for targeted marketing and delivering enhanced customer experiences. 
                    </p>
                </div>
                <div className="step-box datathird">
                    <h2>Identify Target Customers</h2>
                    <p>
                        By identifying and focusing on your most potential customers, we ensure that your marketing efforts are targeted and efficient. Reaching the right audience increases the likelihood of conversions and reduces wasted marketing spend.
                    </p>
                </div>
                <div className="step-box datafour">
                    <h2 style={{color:'black'}}>Tailored Analytics Solutions</h2>
                    <p style={{color:'black'}}>
                    Tailor our ML- and deep learning-based predictive models to align with your business goals.
                    </p>
                </div>
                <div className="step-box positioning">
                    <h2>ESG Analytics Solutions</h2>
                    <p>
                    Ensuring that the insights generated are actionable and relevant to your organization.
                    </p>
                </div>
            </div>
        </div>

        <section className="container">
      <h2 className="expertise-heading">Our Expertise</h2>
      <div className="expertise-cards">
        <div className="expertise-card">
          <h3 className="expertise-title" style={{textAlign:'center'}}>FMCG & Retail</h3>
          <p className="expertise-description" style={{textAlign:'center'}}>
            Our expertise in FMCG & Retail branding brings impactful visual narratives. With a track record of success, we elevate brand presence and consumer connections.
          </p>
        </div>
        <div className="expertise-card">
          <h3 className="expertise-title" style={{textAlign:'center'}}>E-Commerce</h3>
          <p className="expertise-description" style={{textAlign:'center'}}>
          E commerce is our domain. With a record of success, we curate brand stories that capture style, resonate with audiences, and elevate the fashion industry.
          </p>
        </div>
        <div className="expertise-card">
          <h3 className="expertise-title" style={{textAlign:'center'}}> Real Estate & Infra</h3>
          <p className="expertise-description" style={{textAlign:'center'}}>
          Real Estate and Infra  is our strength. With extensive experience, we convey the essence of well-being, fostering connections and trust in the industry
          </p>
        </div>
      </div>
    </section>
</div>
  )
}

export default DataAnalyticsHero