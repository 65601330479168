import React from 'react'
import Navbar from '../../components/Navbar'
import './careers.css';
import Contactads from '../../components/ads3/Contactads';
import Footer from '../../components/footer/Footer';
import CareersHead from '../../components/careershead/CareersHead';
import CareerContent from '../../components/careerscontent/CareerContent';


const Careers = () => {
  return (
  <div className="careers">
    <Navbar/>
    <CareersHead/>
    <CareerContent/>
    <Contactads/>

    <Footer/>
  </div>
  )
}

export default Careers