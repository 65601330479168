import React from 'react'
// import './mainservicehero.css';
import almlhero1 from '../../assets/almlhero1.png'
import almlhero2 from '../../assets/almlhero2.png'
import almlhero4 from '../../assets/almlhero4.png'
import msh1 from '../../assets/msh1.png'


import { FaRegArrowAltCircleRight } from "react-icons/fa";

import erphero1 from '../../assets/erphero1.png'
import AlMlCarousel from './AlMlCarousel'
import { Link } from 'react-router-dom'
const AlMlHero = () => {
  return (
    <div className="mainservicehero">
        <div className="Web">
            <div className="msh">
            <div className='msleft'>
                <div className="msleftfirst">
                    <img  src={msh1} alt="" />
                    <div className="overlay">
        <h1>Elevate Your Company  with AI Solutions</h1>
        {/* <p>Just like gourmet meal, our web development services are tailored to your exact tastes and needs, ensuring a deliciously effective online presence.</p> */}
    </div>
                </div>
                <div className="msleftsecond">
                    {/* <img src={msh2} alt="" />
                    
                    <img src={msh3} alt="" /> */}
                     <div className="image-container">
        <img src={almlhero4} alt="" />
        <div className="overlay1">
            <h3>Custom  LLM</h3>
        
        </div>
    </div>
    <div className="image-container">
        <img src={almlhero1} alt="" />
        <div className="overlay1">
            <h3>Text-to-Speech</h3>
       
        </div>
    </div>
                </div>
             </div>
             <div className="msright">
    <div className="image-container">
        <img src={almlhero2} alt="" />
        <div className="overlay2">
            <h1>IoT Solutions</h1>
         
            <Link to="/contact" className="overlay2btn">
    Let's talk <FaRegArrowAltCircleRight className="btnicn" />
  </Link>
        </div>
    </div>
</div>
            </div>
       
        </div>
        <div className="container">
            <h1>AI & ML <span style={{color:'grey'}} >Services & Solutions</span></h1>
        
        </div>
        <AlMlCarousel/>
    </div>
  )
}

export default AlMlHero