

import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './contact.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer/Footer';

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bsb8pwe', 'template_3n99o9t', form.current, 'dRk0gwTHD_7bG-G7_')
      .then((result) => {
          console.log(result.text);
          alert('Message sent successfully!');
      }, (error) => {
          console.log(error.text);
          alert('Failed to send the message, please try again later.');
      });

    e.target.reset();
  };
  return (
    <>
<Navbar/>
<div className="contact-container">
      <div className="contact-info">
        <h2>Contact Us</h2>
        <p>Let's have Discussions</p>
        {/* <h2 style={{marginTop:'10px'}}><strong>Address</strong></h2> */}
        <h3>Bengaluru</h3>
        <p >Artek Solutions,HRBR Layout 3rd Block, HRBR Layout, Kalyan Nagar,560043, Bengaluru, Karnataka</p>
        <h3 style={{marginTop:'10px'}}>Kochi</h3>
        <p >Artek Solutions,edachira, infopark, Kakkanad,682042,Kerala</p>
        <h3 style={{marginTop:'10px'}}>UAE</h3>
        <p >Artek Solutions,Wadi al safa 5, blue wave tower , dubai land , dubai</p>
        <p><strong>Phone Number</strong></p>
        <p>+91 9947437984</p>
        <p><strong>Email</strong></p>
        <p>info@artek.dev</p>
      </div>
      <div className="contact-form">
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Full Name*" required />
          <input type="email" name="email" placeholder="Email*" required />
          <input type="text" name="phone" placeholder="Phone Number*" required />
          <input type="text" name="service" placeholder="Service*" required />
          <textarea name="message" placeholder="Please tell us a bit about your project" rows="5"></textarea>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default ContactForm;
