import React, { useEffect } from 'react'
import './footer.css';
import AOS from 'aos'
import 'aos/dist/aos.css'
import logo from '../../assets/logo.png'
import { BsFacebook, BsInstagram, BsLinkedin, BsWhatsapp } from 'react-icons/bs';
import { BiPhone } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import logonew from '../../assets/logowhite.png'
const Footer = () => {
    useEffect(()=>{
        AOS.init({duration:1000})
          },[])
  return (


<footer className="footer">
<div className="footer-section">
  <img src={logonew} alt="Artek Solutions Logo" className="footer-logo-image" />
</div>


<div className="footer-section">
    <h3>Services</h3>
    <ul>
    <li><Link to="/webservices">Development</Link></li>
      <li><Link to="/digitalmarketingservices">Digital Marketing</Link></li>
      <li><Link to="/brandingservices">Branding</Link></li>
      <li><Link to="/al&mlservices">Artificial Intelligence</Link></li>
      <li><Link to="/ptaas">PTaaS</Link></li>
      <li><Link to="/designservices">Design</Link></li>
      <li><Link to="/dataanalyticservices">Data & Analytics</Link></li>
      <li><Link to="/eerpservices">ERP, CRM & CMS Development</Link></li>
    </ul>
</div>

<div className="footer-section">
<h3>Home</h3>
    <ul>
    <li><Link to="/works">Works</Link></li>
      <li><Link to="/services">Services</Link></li>
      <li><Link to="/careers">Careers</Link></li>
      <li><Link to="/blogs">Blogs</Link></li>

    </ul>
</div>

<div className="footer-section">
    <h3>Address</h3>
    <ul>
        <li>HRBR Layout 3rd Block, HRBR Layout, Kalyan Nagar, Bengaluru 560043</li>
        <li>Artek Solutions 
Edachira, info park Kakkanad,Kochi
682042</li>
    </ul>
</div>

<div className="footer-section follow-us">
    <h3>Follow Us On</h3>
    <div className="social-icons">
    <a href="https://www.facebook.com/profile.php?id=61565943381962&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
        <BsFacebook />
      </a>
      <a href="https://www.instagram.com/artek.ai?igsh=MWNmdG90dWFtbzd4MQ==" target="_blank" rel="noopener noreferrer">
        <BsInstagram />
      </a>
      <a href="https://www.linkedin.com/company/artek-it-solutions/" target="_blank" rel="noopener noreferrer">
        <BsLinkedin />
      </a>
      <a href="https://wa.me/9947437984" target="_blank" rel="noopener noreferrer">
        <BsWhatsapp />
      </a>

    </div>
    <div className="contact-number">
        <BiPhone/> +91 9947437984
    </div>
</div>
</footer>
  )
}

export default Footer