

import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import AOS from 'aos'
import 'aos/dist/aos.css'
// import './mainservicecarousel.css';

import data1 from '../../assets/dataslider1.png'
import data2 from '../../assets/dataslider2.png'

import data3 from '../../assets/dataslider3.png'

import data4 from '../../assets/dataslider4.png'
import data5 from '../../assets/dataslider5.png'
import data6 from '../../assets/dataslider.png'

import data7 from '../../assets/dataslider7.png'
import data8 from '../../assets/dataslider8.png'
import data9 from '../../assets/dataslider9.png'


import { FreeMode, Mousewheel, Pagination } from 'swiper/modules';
const DataAnalyticsCarousel = () => {
    useEffect(()=>{
        AOS.init({duration:1000})
          },[])
  return (
    <div className='Container' style={{marginTop:'2rem'}}>
    <>
         <Swiper
           slidesPerView={1.5}
           spaceBetween={30}
           freeMode={true}
           pagination={{
             clickable: true,
           }}
           mousewheel={true}
           modules={[FreeMode, Pagination,Mousewheel]}
           className="mySwiper"
           breakpoints={{
             640: { // for screens >= 640px
               slidesPerView: 2,
               spaceBetween: 20,
             },
             768: { // for screens >= 768px
               slidesPerView: 3,
               spaceBetween: 30,
             },
             1024: { // for screens >= 1024px
              slidesPerView: 3.5,
               spaceBetween: 40,
             },
           }}
         >
           <SwiperSlide className='slid' >
             <div className='slidimgsec'>
             <img className='slidimg'  src={data4} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Operation Analytics</h3>
    <p style={{color:'white'}}>Expert guidance and customized analytics solutions tailored to your business needs, ensuring optimized performance and strategic growth.</p>
  </div>
             </div>
    
             </SwiperSlide>
           <SwiperSlide >   <div >
             <img  src={data3} alt="" />
             
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Industry Intelligence</h3>
    <p style={{color:'white'}}>Stay ahead of the competition with comprehensive industry insights and data-driven market intelligence.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={data2} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Big data</h3>
    <p style={{color:'white'}} >Harness large volumes of data to uncover valuable patterns, trends, and opportunities that drive innovation and operational efficiency.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={data1} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Investment analysis</h3>
    <p  style={{color:'white'}} >Make smarter investment decisions with in-depth analysis of market trends, risk assessment, and performance metrics to maximize returns.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={data5} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'>Risk Assessment analytics</h3>
    <p>Gain deep insights into customer behavior, campaign effectiveness, and market trends to drive targeted and impactful marketing strategies.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={data6} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Predictive analytics</h3>
    <p style={{color:'white'}}>Harness the power of data to forecast future trends and make informed decisions with confidence.</p>
  </div>
             </div></SwiperSlide>

             <SwiperSlide >   <div >
             <img  src={data7} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>ESG Analytics</h3>
    <p style={{color:'white'}}>Make smarter investment decisions with in-depth analysis of market trends, risk assessment, and performance metrics to maximize returns.</p>
  </div>
             </div></SwiperSlide>

             <SwiperSlide >   <div >
             <img  src={data8} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Test Analysis & NLP</h3>
    <p style={{color:'white'}}>Through insights based on data and actionable intelligence, we help firms improve their marketing efforts. Our team of seasoned specialists uses cutting-edge analytics methods to assess the efficacy of promotional efforts, locate new avenues for expansion, and maximize return on investment</p>
  </div>
             </div></SwiperSlide>

             <SwiperSlide >   <div >
             <img  src={data9} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Marketing Analytics</h3>
    <p style={{color:'white'}}>We promote ethical and sustainable development by providing full-service ESG (environmental, social, and governance) analytics. By guiding companies toward ESG alignment, our specialists help pave the way for a more environmentally and socially responsible future</p>
  </div>
             </div></SwiperSlide>
        
         </Swiper>
       </>
     </div>
  )
}

export default DataAnalyticsCarousel