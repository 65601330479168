import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide ,} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import AOS from 'aos'
import 'aos/dist/aos.css'
import './mainservicecarousel.css';

import mss1 from '../../assets/mss1.png'
import mss2 from '../../assets/mss2.png'
import mss3 from '../../assets/mss3.png'
import mss4 from '../../assets/mss4.png'
import mss5 from '../../assets/mss5.png'
import mss6 from '../../assets/mss6.png'
import mss7 from '../../assets/mss7.png'


import { FreeMode, Pagination, Navigation, Mousewheel} from 'swiper/modules';
const MainServiceCarousel = () => {
    useEffect(()=>{
        AOS.init({duration:1000})
          },[])
          const swiperRef = React.useRef(null);

  return (
    <div className="Container" style={{marginTop:'2rem'}}>
    <>
         <Swiper
         
           slidesPerView={1.3}
           spaceBetween={20}
           freeMode={true}
           pagination={{
             clickable: true,
           }}
         
          mousewheel={true}
           modules={[FreeMode, Pagination,Mousewheel]}
           className="mySwiper"
           breakpoints={{
             640: { // for screens >= 640px
               slidesPerView: 2,
               spaceBetween: 10,
             },
             768: { // for screens >= 768px
               slidesPerView: 3,
               spaceBetween: 30,
             },
             1024: { // for screens >= 1024px
              slidesPerView: 3.5,
               spaceBetween: 30,
             },
           }}
           onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
         >
    
           <SwiperSlide className='slid' >
             <div className='slidimgsec'>
             <img className='slidimg'  src={mss1} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'black'}}>User Experience & Interface Design (UX/UI)</h3>
    <p style={{color:'black'}}>Crafting intuitive, visually stunning interfaces and seamless user experiences through user-centered design and interactive prototypes</p>
  </div>
             </div>
    
             </SwiperSlide>
           <SwiperSlide >   <div >
             <img  src={mss2} alt="" />
             
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'black'}}>Custom Web Design & Development</h3>
    <p style={{color:'black'}}>Creating unique, responsive websites tailored to your brand, with seamless experiences across all devices.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={mss3} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'>Advanced Features & Integrations</h3>
    <p>Implementing AI-driven features, augmented reality (AR), IoT integration, and connecting with third-party APIs for enhanced app functionality.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={mss4} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'black'}}>E-commerce Solutions</h3>
    <p style={{color:'black'}}>Developing secure, feature-rich online stores with platforms like WooCommerce, Shopify, and Magento, complete with custom payment gateways and inventory management.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={mss5} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Performance Optimization & Security</h3>
    <p style={{color:'white'}}>Ensuring fast, efficient performance and robust security with optimization techniques, encryption, and regular vulnerability assessments.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={mss6} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'black'}}>Maintenance & Support</h3>
    <p style={{color:'black'}}>Providing ongoing app & web maintenance, regular updates, bug fixes, and 24/7 support to ensure your app & web remains up-to-date and reliable.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={mss7} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'white'}}>Back-End Development</h3>
    <p style={{color:'white'}}>Building robust, scalable server-side applications with Node.js, Python, Ruby on Rails, or PHP, and integrating SQL and NoSQL databases.</p>
  </div>
             </div></SwiperSlide>
         </Swiper>
       
       </>
     </div>
  )
}

export default MainServiceCarousel