import React from 'react'

import './app.css';


import Home from './pages/home/Home';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import About from './pages/about/About';
import Services from './pages/services/Services';
import Contact from './pages/contact/Contact';
import Project from './pages/project/Project';
import Navbar from './components/Navbar';
import Works from './pages/works/Works';
import Careers from './pages/careers/Careers';
import Blogs from './pages/blogs/Blogs';
import DesingService from './pages/designservice/DesingService';
import AlMlServices from './pages/al&mlservices/AlMlServices';
import BrandingService from './pages/brandingservice/BrandingService';
import DigitalMarketingServices from './pages/digitalmarketingservice/DigitalMarketingServices';
import DataAnalyticsServices from './pages/dataanalytics&services/DataAnalyticsServices';
import ErpServices from './pages/erpservices/ErpServices';
import MainService from './pages/webdevelopmentservices/MainService';
import PtaasServices from './pages/ptaasservices/PtaasServices';


const App = () => {
  return (
    <BrowserRouter>
    {/* <Navbar/> */}
    <Routes>
     <Route path='/' element={<Home/>} />
     <Route path='/contact' element={<Contact/>}/>
     <Route path='/works' element={<Works/>}/>
     <Route path='/careers' element={<Careers/>}/>
     <Route path='/blogs' element={<Blogs/>}/>
     <Route path='/services' element={<Services/>}/>
     <Route path='/webservices' element={<MainService/>}/>
     <Route path='/erpservices' element={<ErpServices/>}/>
     <Route path='/al&mlservices' element={<AlMlServices/>}/>
     <Route path='/brandingservices' element={<BrandingService/>}/>
     <Route path='/designservices' element={<DesingService/>}/>
     <Route path='/digitalmarketingservices' element={<DigitalMarketingServices/>}/>
     <Route path='/dataanalyticservices' element={<DataAnalyticsServices/>}/>
     <Route path='/ptaas' element={<PtaasServices/>}/>





    </Routes>
 </BrowserRouter>
  )
}

export default App