import React from 'react'
import improvement from '../../assets/improvementnew.png'
import Potentialorg from '../../assets/landingnew4.png'
import booster from '../../assets/Boosternew.png'
// import improvsecond from '../../assets/Rectangle 62489.png'
import improvsecond from '../../assets/landing34.png'
import './display.css';
import { Link } from 'react-router-dom'


const Display = () => {
  return (
    <div className="display">
{/* <div className="container ">
    <div className='dissec'>

      <div className='firstsec'>
     <img   className='hlo' src={improvement} alt="" />
      </div>
      <div className='secondsec'>
         <h1>Artek Kaizen</h1>
         <span>Driving Continuous Growth and Improvement</span>
         <p>The Artek Kaizen Strategy is a comprehensive approach designed to 
            help companies achieve their growth objectives and become industry
             leaders through relentless improvement and strategic initiatives.</p>
             <span>How Artek Kaizen works?</span>
             <Link to={'/contact'}>
       <button className='btnsecond'>Contact Us</button>
       </Link>
      </div>
    </div>

    <div className='dissec'>

      <div className='fourthsec'>
     <img  className='hlo' src={ improvsecond} alt="" />
      </div>
      <div className='fourthsec1'>
         <h1>Artek Growth Booster</h1>
         <span>Driving Continuous Growth and Improvement
         </span>
         <p>The Artek Kaizen Strategy is a comprehensive approach designed to help companies achieve their growth objectives and become industry leaders through relentless improvement and strategic initiatives.

</p>
             <span>How Artek Kaizen works?</span>
             <Link to={'/contact'}>
       <button className='btnsecond'>Contact Us</button>
       </Link>
      </div>
    </div>
    <div className='dissec'>

<div className='thirdsec'>
<img   className='hlo' src={Potentialorg } alt="" />

</div>
<div className='thirdsec1'>
   <h1>Artek Kickstart</h1>
   <span>Unlock Your Brand's Potential with Artek</span>
   <p>Launching a product is a completely different art and science in itself. And this is where a lot of entrepreneurs struggle to make it through</p>
       <span>How Artek Kickstart works?</span>
       <Link to={'/contact'}>
       <button className='btnsecond'>Contact Us</button>
       </Link>

</div>
</div>

</div> */}
 <div className="container">
      {/* First Section */}
      <div className="content-section">
   
        <div className="section-text">
          <h1>Your Brand’s Next Chapter Starts Here</h1>
          <p>
            Launching a product takes skill and strategy—it’s where many entrepreneurs stumble.
            We’re here to ensure you don’t.
          </p>
          <p><strong>Artek Kickstart</strong> will help you get your customers the right way.</p>
          <p className="link-text">How Artek Kickstart works?</p>
          <Link to={'/contact'}>
       <button className='btnsecond'>Contact Us</button>
       </Link>
        </div>
        <img
          className="section-image"
          src={Potentialorg}
          alt="Placeholder"
        />
      </div>

      {/* Second Section */}
      <div className="content-section reverse">
        <div className="section-text">
          <h1>Simple Steps to Elevate Your Business</h1>
          <p>Discover the <strong>Artek Kaizen</strong> Approach</p>
          <p>
            The Artek Kaizen Strategy drives growth and industry leadership through continuous
            improvement and strategic initiatives.
          </p>
          <p className="link-text">How Artek Kaizen works?</p>
          <Link to={'/contact'}>
       <button className='btnsecond'>Contact Us</button>
       </Link>
        </div>
        <img
          className="section-image"
          src={improvement}
          alt="Placeholder"
        />
      </div>

      {/* Third Section */}
      <div className="content-section">
     
        <div className="section-text">
          <h1>Simple Steps to Elevate Your Business</h1>
          <p>
            Launching a product is a completely different art and science in itself.
            And this is where a lot of entrepreneurs struggle to make it through.
          </p>
          <p><strong>Artek Kickstart</strong> will help you get your 100 customers in 90 days the right way.</p>
          <p className="link-text">How Artek Kickstart works?</p>
          <Link to={'/contact'}>
       <button className='btnsecond'>Contact Us</button>
       </Link>
        </div>
        <img
          className="section-image"
          src={booster}
          alt="Placeholder"
        />
      </div>
    </div>
    </div>
  )
}

export default Display