
import './ads.css'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

import Landingimg1 from '../../assets/development.png'
import Landingimg2 from '../../assets/marketing.png'
import Landingimg3 from '../../assets/branding.png'
import Landingimg4 from '../../assets/mlnew.png'
import Landingimg5 from '../../assets/landing5.png'
import Landingimg6 from '../../assets/landing6.png'
import Landingimg7 from '../../assets/dataanalytics.png'
import Landingimg8 from '../../assets/erpcrm.png'
import Landingimg9 from '../../assets/mlland.png'

const Ads = () => {
  useEffect(()=>{
    AOS.init({duration:1000})
      },[])
  return (
    <section className='ads' id='services' >
      <div className="container ">
      <div className="he">
          <h1 className='ads_heading' >What we do </h1>
          <h3 className='ads_sub' >Unleash your potential </h3>
        </div>
    

        <div className="content">
        <div className="box"  data-aos="fade-up">
        <Link to={'/webservices'}>
            <div className="sbox">
      
            <img  src={Landingimg1} alt="grp" />
      
  <div className="text-overlayservice">
    <h3 style={{color:'white'}} className='servimgheadservice'>Development</h3>
    <p style={{color:'white'}} >Our developers ensure seamless cross-device user experiences from backend to frontend.</p>
  </div>

              
            </div>
            </Link>
        </div>
        <div className="box"  data-aos="fade-up">
        <Link to={'/digitalmarketingservices'}>
        <div className="sbox">
      
      <img src={Landingimg2} alt="grp" />
      <div className="text-overlayservice">
    <h3 style={{color:'white'}} className='servimgheadservice'>Digital Marketing</h3>
    <p style={{color:'white'}} >Do you want traffic? We steer your digital growth using rock-solid, data-driven strategies.</p>
  </div>
        
      </div>
      </Link>
        </div>
        <div className="box"  data-aos="fade-up">
        <Link to={'/brandingservices'}>
        <div className="sbox">
      
      <img src={Landingimg3} alt="grp" />
      <div className="text-overlayservice">
    <h3 style={{color:'white'}} className='servimgheadservice'>Branding</h3>
    <p style={{color:'white'}} >Beyond a logo and colors, we'll shape a unique brand identity, assets, and guidelines for consistent touchpoints.</p>
  </div>
        
      </div>
      </Link>
        </div>
        <div className="box"  data-aos="fade-up">
        <Link to={'/al&mlservices'}>

        <div className="sbox">
      
      <img src={Landingimg4} alt="grp" />
      <div className="text-overlayservice">
    <h3 style={{color:'white'}} className='servimgheadservice'>Artificial Intelligence </h3>
    <p style={{color:'white'}} >We unleash the power of AI as a seamless service, enhancing your digital experience.</p>
  </div>
        
      </div>
      </Link>

        </div>
        <div className="box"  data-aos="fade-up">
        <Link to={'/ptaas'}>

        <div className="sbox">
      
      <img src={Landingimg5} alt="grp" />
      <div className="text-overlayservice">
    <h3 style={{color:'white'}} className='servimgheadservice'>PTAAS</h3>
    <p style={{color:'white'}} >Drive business growth through cutting-edge Product Team as a Service.


    </p>
  </div>
        
      </div>
      </Link>

        </div>
        <div className="box"  data-aos="fade-up">
        <Link to={'/designservices'}>

        <div className="sbox">
      
      <img src={Landingimg6} alt="grp" />
      <div className="text-overlayservice">
    <h3 style={{color:'black'}} className='servimgheadservice'>Design</h3>
    <p style={{color:'black'}} >We blend aesthetics and behavioral science to create engaging digital products for modern brands.</p>
  </div>
        
      </div>
      </Link>
        </div>

        <div className="box"  data-aos="fade-up">
        <Link to={'/dataanalyticservices'}>

        <div className="sbox">
      
      <img src={Landingimg7} alt="grp" />
      <div className="text-overlayservice">
    <h3 style={{color:'black'}} className='servimgheadservice'>Data analytics & Consulting</h3>
    <p style={{color:'black'}} >Actionable insights, data-driven decisions, advanced analytics, optimized business performance.</p>
  </div>
        
      </div>
      </Link>
        </div>

        <div className="box"  data-aos="fade-up">
        <Link to={'/erpservices'}>

        <div className="sbox">
      
      <img src={Landingimg8} alt="grp" />
      <div className="text-overlayservice">
    <h3 style={{color:'white'}} className='servimgheadservice'>ERP, CRM & cms development</h3>
    <p style={{color:'white'}} >Custom solutions, streamlinedopera-
    tions, enhanced customer relationships, efficient content management.</p>
  </div>
        
      </div>
      </Link>
        </div>
        <div className="box"  data-aos="fade-up">
        <Link to={'/al&mlservices'}>

        <div className="sbox">
      
      <img src={Landingimg9} alt="grp" />
      <div className="text-overlayservice">
    <h3 style={{color:'white'}} className='servimgheadservice'>Machine learning</h3>
    <p style={{color:'white'}} >Artek's machine learning solutions automate processes and enhance decision-making with advanced data insights</p>
  </div>
        
      </div>
      </Link>
        </div>


       </div>
    
     
      </div>
   
     
    </section>
  )
}

export default Ads