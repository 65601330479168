
import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import AOS from 'aos'
import 'aos/dist/aos.css'
// import './mainservicecarousel.css';

import designslider1 from '../../assets/designslide1.png'
import designslider2 from '../../assets/designslide2.png'

import designslider3 from '../../assets/designslide3.png'

import designslider4 from '../../assets/designslide4.png'
import designslider5 from '../../assets/designslide5.png'

import designslider6 from '../../assets/designslide6.png'





import { FreeMode, Pagination ,Mousewheel} from 'swiper/modules';
const DesignCarousel = () => {
    useEffect(()=>{
        AOS.init({duration:1000})
          },[])
  return (
      <div className="Container" style={{marginTop:'2rem'}}>
    <>
         <Swiper
           slidesPerView={1.6}
           spaceBetween={20}
           freeMode={true}
           pagination={{
             clickable: true,
           }}
           mousewheel={true}
           modules={[FreeMode, Pagination,Mousewheel]}
           className="mySwiper"
           breakpoints={{
             640: { // for screens >= 640px
               slidesPerView: 2.5,
               spaceBetween: 20,
             },
             768: { // for screens >= 768px
               slidesPerView: 3,
               spaceBetween: 30,
             },
             1024: { // for screens >= 1024px
               slidesPerView: 3.5,
               spaceBetween: 30,
             },
           }}
         >
           <SwiperSlide className='slid' >
             <div className='slidimgsec'>
             <img className='slidimg'  src={designslider1} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'white'}}>Consumer & Enterprise Software</h3>
    <p  style={{color:'white'}}>Crafting words that resonate, establishing a memorable brand voice.</p>
  </div>
             </div>
    
             </SwiperSlide>
           <SwiperSlide >   <div >
             <img  src={designslider2} alt="" />
             
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'black'}}>Design Systems</h3>
    <p style={{color:'black'}}>Streamlined guidelines ensuring consistent and efficient design iterations.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={designslider3} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'black'}}>Web Design</h3>
    <p  style={{color:'black'}}>Tailored designs bringing brands to life in the digital realm.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={designslider4} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'white'}}>UI Design & Strategies</h3>
    <p  style={{color:'white'}}>Creating visually captivating interfaces that engage and delight users.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={designslider5} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'black'}}>CX, UX & BX Design</h3>
    <p style={{color:'black'}}>Elevating user satisfaction through intuitive interactions and compelling experiences.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={designslider6} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'black'}}>User Research & Strategy</h3>
    <p  style={{color:'black'}}>Data-driven insights shaping user-friendly designs through thorough strategy.</p>
  </div>
             </div></SwiperSlide>
         </Swiper>
       </>
     </div>
  )
}

export default DesignCarousel