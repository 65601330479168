
import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import AOS from 'aos'
import 'aos/dist/aos.css'
// import './mainservicecarousel.css';

import digitalslider1 from '../../assets/digitalslider1.png'
import digitalslider2 from '../../assets/digitalslider2.png'

import digitalslider3 from '../../assets/digitalslider3.png'

import digitalslider4 from '../../assets/digitialslider4.png'

import digitalslider5 from '../../assets/digitialslider5.png'

import digitalslider6 from '../../assets/digitalslider6.png'
import digitalslider7 from '../../assets/digitialslider7.png'
import digitalslider8 from '../../assets/digitalslider8.png'
import digitalslider9 from '../../assets/digitialslider9.png'
import digitalslider10 from '../../assets/digitalslider10.png'
import digitalslider11 from '../../assets/digiitalslider11.png'
import digitalslider12 from '../../assets/digitialslider6.png'





import { FreeMode, Mousewheel, Pagination } from 'swiper/modules';
import Display from '../displaysec/Display';
const DigitalMarketingCarousel = () => {
    useEffect(()=>{
        AOS.init({duration:1000})
          },[])
  return (
    <>

      <div className="Container" style={{marginTop:'2rem'}}>
    <>
         <Swiper
           slidesPerView={1.6}
           spaceBetween={20}
           freeMode={true}
           pagination={{
             clickable: true,
           }}
           mousewheel={true}
           modules={[FreeMode, Pagination,Mousewheel]}
           className="mySwiper"
           breakpoints={{
             640: { // for screens >= 640px
               slidesPerView: 2.5,
               spaceBetween: 20,
             },
             768: { // for screens >= 768px
               slidesPerView: 3,
               spaceBetween: 30,
             },
             1024: { // for screens >= 1024px
              slidesPerView: 3.5,
               spaceBetween: 30,
             },
           }}
         >
           <SwiperSlide className='slid' >
             <div className='slidimgsec'>
             <img className='slidimg'  src={digitalslider11} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'black'}}>Performance Marketing (PPC)</h3>
    <p  style={{color:'black'}}>Instantly attract visitors with pay-per-click ads for effective results.</p>
  </div>
             </div>
    
             </SwiperSlide>
           <SwiperSlide >   <div >
             <img  src={digitalslider10} alt="" />
             
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Social Media Marketing (SMM)</h3>
    <p style={{color:'white'}}>Engage and expand audience reach through compelling social media campaigns.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={digitalslider9} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'white'}}>Search Engine Optimization </h3>
    <p  style={{color:'white'}}>Elevate online visibility through optimized strategies and search engine rankings</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={digitalslider8} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'black'}}>Search Engine Marketing</h3>
    <p  style={{color:'black'}}>Optimize and manage online ads to increase visibility and drive traffic.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={digitalslider7} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Marketing Automation</h3>
    <p style={{color:'white'}}>Streamline and automate marketing tasks to enhance efficiency and results.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={digitalslider12} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'white'}}>Google Analytics GA4 & Big Query</h3>
    <p  style={{color:'white'}}>Optimize website performance to convert visitors into valuable customers.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={digitalslider6} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'black'}}>Customer Relationship Management</h3>
    <p  style={{color:'black'}}>Direct-to-customer marketing bypasses retailers, engaging customers directly.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={digitalslider5} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'white'}}>Affiliate Marketing</h3>
    <p style={{color:'white'}}>Drive targeted traffic with strategic search engine marketing campaigns.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={digitalslider4} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'white'}}>PR Marketing</h3>
    <p style={{color:'white'}}>Utilize artificial intelligence to enhance campaigns, targeting, and personalization.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={digitalslider3} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'white'}}>Integrated Marketing Strategy</h3>
    <p style={{color:'white'}}>Manage and analyze customer interactions to improve retention and loyalty.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={digitalslider2} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'white'}}>Brand Marketing Strategy</h3>
    <p style={{color:'white'}}>Develop comprehensive plans to build, promote, and sustain brand growth.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={digitalslider1} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'white'}}>Data-Drive Paid Media Strategy</h3>
    <p style={{color:'white'}}>Utilize data insights to optimize paid media campaigns for effectiveness.</p>
  </div>
             </div></SwiperSlide>
         </Swiper>
   
       </>
     </div>
     <Display/>
     </>
  )
}

export default DigitalMarketingCarousel