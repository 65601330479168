import React from 'react'
import './careercontent.css';
import carimg from '../../assets/Rectangle 92.png'
import carimg1 from '../../assets/Rectangle 94.png'
import carimg2 from '../../assets/Rectangle 96.png'

const CareerContent = () => {
  return (
   <div className="careercontent">
    <div className="container">
     <div>
        <h1 className='careerhead'>Why Join Our Team?</h1>
     </div>
     <div><p className='careerpara'>At Artek, we don’t just provide jobs; we create pathways to extraordinary careers. 
        Immerse yourself in an environment where innovation thrives, personal growth is nurtured, 
        and collaboration fuels success. Here, your potential is unbound, and your impact is limitless. 
        Join us and be part of a team that is transforming the tech landscape, 
        one breakthrough at a time.</p></div>
     <div className='imgcontent'>
        <div>
            <div className="imgplace"> <img src={carimg} alt="" /></div>
            <div className="imgdesc">
                <h4>Innovative Environment</h4>
                <span>Be at the forefront of technological advancements. At Artek, 
                    you'll work on cutting-edge projects that challenge you to think creatively and push boundaries,
                     ensuring you stay ahead in the ever-evolving tech world.</span>
            </div>
        </div>


        <div>
            <div className="imgplace"> <img src={carimg1} alt="" /></div>
            <div className="imgdesc">
                <h4>Innovative Environment</h4>
                <span>Be at the forefront of technological advancements. At Artek, 
                    you'll work on cutting-edge projects that challenge you to think creatively and push boundaries,
                     ensuring you stay ahead in the ever-evolving tech world.</span>
            </div>
        </div>



        <div>
            <div className="imgplace"> <img src={carimg2} alt="" /></div>
            <div className="imgdesc">
                <h4>Innovative Environment</h4>
                <span>Be at the forefront of technological advancements. At Artek, 
                    you'll work on cutting-edge projects that challenge you to think creatively and push boundaries,
                     ensuring you stay ahead in the ever-evolving tech world.</span>
            </div>
        </div>



   
     </div>
    </div>
   </div>
  )
}

export default CareerContent