import React, { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { BsPerson } from 'react-icons/bs'
import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import { FaFacebook, FaInstagram, FaPinterest, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa'
// import logo from '../assets/logoletter.png'

import artekblack from '../assets/artekblack.png'

import './navbar.css'
import { Link } from 'react-router-dom'

    function Navbar() {
        const [nav, setNav] = useState(false)
        const handleNav = () => setNav(!nav)

        return (
            <div name='home' className={nav ? 'navbar navbar-bg' : 'navbar'}>
                <div className={nav ? 'logo dark' : 'logo'}>
            
                    {/* <img  style={{height:'155px',width:'100%',objectFit:'cover'}} src='' alt="logo" /> */}
                    <Link to={'/'} className='custom-link' >
                    <img src={artekblack} alt="Artek Solutions Logo" className="logo-image" />
                    </Link>
           
                </div>
                <ul className="nav-menu">


            <a href='/services'  className='menu-link custom-link' >Services</a>
        <a href='/works'  className='menu-link custom-link' >Works</a>
        <a href='/careers'  className='menu-link custom-link' >Careers</a>
        <a href='/blogs'  className='menu-link custom-link' >Blogs</a>
        <Link to={'/contact'} >
        <button className='btn-head' >Contact</button>
        </Link>
    
                </ul>
         
                <div className="hamburger" onClick={handleNav}>
                    {!nav ? (<HiOutlineMenuAlt4 className='icon' color='black'  />) : (<AiOutlineClose style={{ color: 'black' }} className='icon' />)}
                </div>
                <div className={nav ? 'mobile-menu active' : 'mobile-menu'}>
                    <div className="mobile-nav">
                <div  style={{display:'flex',flexDirection:'column' }} >
               
                <a href='/' onClick={handleNav} className='menu-link' style={{color:'black' }}>Home</a>
        {/* <a href='#about' onClick={handleNav}  className='menu-link' style={{color:'black' }} >About</a> */}
        <a href='/services' onClick={handleNav} className='menu-link' style={{color:'black' }}>Services</a>
                </div>

        <div style={{display:'flex',flexDirection:'column'}}>
        <a href='/blogs' onClick={handleNav} style={{color:'black' }} className='menu-link1'>Blogs</a>
        <a href='/careers' onClick={handleNav} style={{color:'black' }} className='menu-link1'  >Careers</a>
        <a href='/contact' onClick={handleNav} style={{color:'black' }} className='menu-link1' >Contact</a>
        </div>
                    </div>
                    <div className="mobile-menu-bottom">
                        <div className="menu-icons">
                    
                            <h3>have a Project in mind?</h3>
                
                            <Link to={'/contact'} >
                            <button className='btntalk'>Let's Talk</button>
                            </Link>
                  
                          <div><a>india</a>
                          <a>dubai</a></div>
                        </div>
                        <div className="social-icons">
                    
                            <a onClick={handleNav} href="https://wa.me/9656131999" target="_blank">
    <FaWhatsapp className='icon' />
    </a>
                    
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    export default Navbar
