import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import Group3 from '../../assets/landingcarousel1.png'
import Group4 from '../../assets/landingcarousel2.png'
import Group5 from '../../assets/landingcarousel3.png'
import Group6 from '../../assets/landingcarousel4.png'
import Group7 from '../../assets/landingcarousel5.png'



import './carousel.css';


import AOS from 'aos'
import 'aos/dist/aos.css'

import { FreeMode, Mousewheel, Pagination } from 'swiper/modules';
const Carousel = () => {
  useEffect(()=>{
    AOS.init({duration:1000})
      },[])
  return (
    <div className="Container " style={{marginTop:'6rem'}}>
 <>
      <Swiper
        slidesPerView={1.3}
        spaceBetween={10}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        mousewheel={true}
        modules={[FreeMode, Pagination,Mousewheel]}
        className="mySwiper"
        breakpoints={{
          640: { // for screens >= 640px
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          768: { // for screens >= 768px
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: { // for screens >= 1024px
            slidesPerView: 3.5,
            spaceBetween: 20,
          },
        }}
      >
        <SwiperSlide className='slid' >
          <div className='slidimgsec'>
          <img className='slidimg'  src={Group3} alt="" />
          </div>
 
          </SwiperSlide>

        <SwiperSlide >   <div >
          <img  src={Group4} alt="" />
          </div></SwiperSlide>

        

    
          <SwiperSlide >   <div >
          <img  src={Group7} alt="" />
          </div></SwiperSlide>
          <SwiperSlide >   <div >
          <img  src={Group6} alt="" />
          </div></SwiperSlide>
          <SwiperSlide >   <div >
          <img  src={Group5} alt="" />
          </div></SwiperSlide>
      </Swiper>
    </>
  </div>
  )
}

export default Carousel