import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar'
import AlMlHero from '../../components/alml/AlMlHero'
import Contactads from '../../components/ads3/Contactads'
import Footer from '../../components/footer/Footer'

const AlMlServices = () => {
  useEffect (() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
  <div className="almlservices">
    <Navbar/>
    <AlMlHero/>
    <Contactads/>
    <Footer/>
  </div>
  )
}

export default AlMlServices