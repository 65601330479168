import React from 'react'
import Navbar from '../../components/Navbar'
import Contactads from '../../components/ads3/Contactads'
import Footer from '../../components/footer/Footer'
import WorksContent from '../../components/workscontent/WorksContent'

const Works = () => {
  return (
    <div className="works">
      <Navbar/>
      <WorksContent/>
      <Contactads/>
      <Footer/>
    </div>
  )
}

export default Works