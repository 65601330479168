import React from 'react'
import Navbar from '../../components/Navbar'
import Contactads from '../../components/ads3/Contactads'
import Footer from '../../components/footer/Footer'
import BlogContent from '../../components/blogcontent/BlogContent'

const Blogs = () => {
  return (
   <div className="blogs">
    <Navbar/>
<BlogContent/>
  <Contactads/>
  <Footer/>
   </div>
  )
}

export default Blogs