
import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import AOS from 'aos'
import 'aos/dist/aos.css'
// import './mainservicecarousel.css';

import erpslider1 from '../../assets/erpslider1.png'
import erpslider2 from '../../assets/erpslider2.png'
import erpslider3 from '../../assets/erpslider3.png'
import erpslider4 from '../../assets/erpslider4.png'
import erpslider5 from '../../assets/erpslider5.png'
import erpslider6 from '../../assets/erpslider6.png'
import erpslider7 from '../../assets/erplslider7.png'
import erpslider8 from '../../assets/erpslider8.png'
import erpslider9 from '../../assets/erpslider9.png'
import erpslider10 from '../../assets/erpslider10.png'



import { FreeMode, Mousewheel, Pagination } from 'swiper/modules';
const ErpCarousel = () => {
    useEffect(()=>{
        AOS.init({duration:1000})
          },[])
  return (
      <div className="Container" style={{marginTop:'2rem'}}>
    <>
         <Swiper
           slidesPerView={1.6}
           spaceBetween={20}
           freeMode={true}
           pagination={{
             clickable: true,
           }}
           mousewheel={true}
           modules={[FreeMode, Pagination,Mousewheel]}
           className="mySwiper"
           breakpoints={{
             640: { // for screens >= 640px
               slidesPerView: 2.5,
               spaceBetween: 20,
             },
             768: { // for screens >= 768px
               slidesPerView: 3,
               spaceBetween: 30,
             },
             1024: { // for screens >= 1024px
               slidesPerView: 3.5,
               spaceBetween: 30,
             },
           }}
         >
           <SwiperSlide className='slid' >
             <div className='slidimgsec'>
             <img className='slidimg'  src={erpslider1} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'>Custom ERP/CRM/CSM Solutions</h3>
    <p>We create bespoke, scalable ERP, CRM, and CMS solutions tailored to your unique business needs.</p>
  </div>
             </div>
    
             </SwiperSlide>
           <SwiperSlide >   <div >
             <img  src={erpslider2} alt="" />
             
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'black'}}>ERP/CRM Integration</h3>
    <p style={{color:'black'}}>Seamlessly integrate ERP and CRM with existing software, third-party services, and APIs for smooth data exchange.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={erpslider3} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'black'}}>Comprehensive Modules</h3>
    <p style={{color:'black'}}>Key modules including Financial Management, Human Resources, Inventory Management, Sales & CRM, and Manufacturing.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={erpslider4} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'>Cloud & On-Premise Solutions</h3>
    <p>Flexible cloud ERP solutions and robust on-premise systems to suit different business environments.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={erpslider5} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'Black'}}>Automation & Workflow Management</h3>
    <p style={{color:'black'}}>Implementing automation for sales, marketing, and customer service workflows to increase efficiency and streamline operations.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={erpslider6} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'>Analytics & Reporting</h3>
    <p>Providing advanced analytics and reporting tools to gain insights into customer behavior, sales performance, and campaign effectiveness.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={erpslider7} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'white'}}>Popular CMS Platforms</h3>
    <p style={{color:'white'}}>Expertise in WordPress, Joomla, and Drupal for dynamic and versatile website development, ensuring robust performance and customization.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={erpslider8} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'white'}}>Headless CMS</h3>
    <p style={{color:'white'}}>Implementing headless CMS solutions like Contentful, Strapi, and Sanity for content delivery across multiple channels and devices.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={erpslider9} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'white'}}>Maintenance & Support</h3>
    <p style={{color:'white'}}>We provide ongoing maintenance, regular updates, user training, and 24/7 support for ERP, CRM, and CMS systems.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={erpslider10} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'BLACK'}}>E-commerce Integration</h3>
    <p style={{color:'BLACK'}}>Creating feature-rich online stores with WooCommerce, Shopify, and Magento, integrated seamlessly with your CMS for a unified experience.</p>
  </div>
             </div></SwiperSlide>
         </Swiper>
       </>
     </div>
  )
}

export default ErpCarousel