
import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import AOS from 'aos'
import 'aos/dist/aos.css'
// import './mainservicecarousel.css';

import alslider1 from '../../assets/almlslider1.png'
import alslider2 from '../../assets/almlslider2.png'
import alslider3 from '../../assets/almlslider3.png'
import alslider4 from '../../assets/almlslider4.png'
import alslider5 from '../../assets/almlslider5.png'




import { FreeMode, Pagination , Mousewheel } from 'swiper/modules';
const AlMlCarousel = () => {
    useEffect(()=>{
        AOS.init({duration:1000})
          },[])
  return (
      <div className="Container" style={{marginTop:'2rem'}}>
    <>
         <Swiper
           slidesPerView={1.6}
           spaceBetween={20}
           freeMode={true}
           pagination={{
             clickable: true,
           }}
           mousewheel={true}
           modules={[FreeMode, Pagination , Mousewheel]}
           className="mySwiper"
           breakpoints={{
             640: { // for screens >= 640px
               slidesPerView: 2.5,
               spaceBetween: 20,
             },
             768: { // for screens >= 768px
               slidesPerView: 3,
               spaceBetween: 30,
             },
             1024: { // for screens >= 1024px
              slidesPerView: 3.5,
               spaceBetween: 20,
             },
           }}
         >
           <SwiperSlide className='slid' >
             <div className='slidimgsec'>
             <img className='slidimg'  src={alslider1} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>AI Consulting & Strategy</h3>
    <p style={{color:'white'}}>Offering expert consultation on AI strategy, implementation, ethical AI practices, and providing training to help your team leverage AI technologies effectively.</p>
  </div>
             </div>
    
             </SwiperSlide>
           <SwiperSlide >   <div >
             <img  src={alslider2} alt="" />
             
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' >AI Integration & APIs</h3>
    <p >Integrating AI capabilities from providers like Google Cloud AI, IBM Watson, and Microsoft Azure AI into existing systems for enhanced functionality.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={alslider3} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Data Analytics & Insights  </h3>
    <p style={{color:'white'}}>Leveraging big data analytics and predictive models to provide actionable business insights and drive informed decision-making.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={alslider4} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>AI-Powered Automation</h3>
    <p style={{color:'white'}}>Implementing robotic process automation (RPA) and intelligent process automation (IPA) to streamline and optimize business operations.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={alslider5} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>AI-Powered Automation</h3>
    <p style={{color:'white'}}>Implementing robotic process automation (RPA) and intelligent process automation (IPA) to streamline and optimize business operations.</p>
  </div>
             </div></SwiperSlide>
         </Swiper>
       </>
     </div>
  )
}

export default AlMlCarousel