import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar'
import Contactads from '../../components/ads3/Contactads'
import Footer from '../../components/footer/Footer'
import DataAnalyticsHero from '../../components/dataanalytics/DataAnalyticsHero'

const DataAnalyticsServices = () => {
  useEffect (() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div className="dataanalytics">
      <Navbar/>
      <DataAnalyticsHero/>
      <Contactads/>
      <Footer/>
    </div>
  )
}

export default DataAnalyticsServices