import React from 'react'
import './servicecnt.css';
import servimg1 from '../../assets/Group 137007.png'
import servimg2 from '../../assets/Group 137008.png'
import servimg3 from '../../assets/Group 137009.png'
import servimg4 from '../../assets/Group 137010.png'
import servimg5 from '../../assets/Group 137011.png'
import servimg6 from '../../assets/Group 137012.png'
import servimg7 from '../../assets/Group 137013.png'
import servimg8 from '../../assets/Group 137014.png'
import landingimg1 from '../../assets/landing1.png'
import Landingimg2 from '../../assets/landing2.png'
import Landingimg3 from '../../assets/landing3.png'
import Landingimg4 from '../../assets/landing4.png'
import Landingimg5 from '../../assets/landing5.png'
import Landingimg6 from '../../assets/landing6.png'
import Landingimg7 from '../../assets/landing7.png'
import Landingimg8 from '../../assets/landing8.png'
import angular from '../../assets/Angular_JS 2.png'
import vue from '../../assets/Vuejs 2.png'
import react from '../../assets/react 6.png'
import Next from '../../assets/next_js 2.png'
import Jvacript from '../../assets/javascript 2.png'
import Java from '../../assets/java 2.png'
import Php from '../../assets/php-logo 2.png'
import Python from '../../assets/Python-logo-notext 2.png'
import Node from '../../assets/ico-tech-node-1 2.png'
import Mysql from '../../assets/Mysql 3.png'
import Kotlin from '../../assets/kotlin-android 3.png'
import django  from '../../assets/icons8-django-500 2.png'
import mongo from '../../assets/kuyjf3vea2hg34taa-horizontal_default_slate_blue 2.png'
import powerbi from '../../assets/icons8-power-bi-2021-48 2.png'
import Flask from '../../assets/flask-horizontal 2.png'
import adobe from '../../assets/illustrator (1) 2.png'
import xd from '../../assets/xd 2.png'
import laravel from '../../assets/react 7.png'
import photoshop from '../../assets/photoshop (1) 2.png'
import figma from '../../assets/figma (4) 2.png'
import seab from '../../assets/logo-wide-lightbg 2.png'
import tensorflow from '../../assets/lockup 2.png'
import { Link } from 'react-router-dom';



const technologies = [
  { name: 'Angular', icon: angular },
  { name: 'React', icon: react },
  { name: 'Vue.js', icon: vue },
  { name: 'Next.js', icon: Next },
  { name: 'Javascript', icon: Jvacript },
  { name: 'Laravel', icon: laravel },
  { name: 'Python', icon: Python },
  { name: 'Java', icon: Java },
  { name: 'PHP', icon: Php },
  { name: 'Node.js', icon: Node },
  { name: 'MySQL', icon:  Mysql },
  { name: 'Kotlin', icon: Kotlin },
  { name: 'Django', icon: django  },
  { name: 'MongoDB', icon: mongo },
  { name: 'TensorFlow', icon: tensorflow },
  { name: 'Power Bi', icon: powerbi },
  { name: 'Seaborn', icon: seab },
  { name: 'Flask', icon: Flask },
  { name: 'Figma', icon: figma  },
  { name: 'Adobe Photoshop', icon: photoshop },
  { name: 'Adobe Illustrator', icon: adobe  },
  { name: 'Adobe XD', icon: xd },
];


const ServiceCont = () => {
  return (
    <div className="servicecont1">

        <div className="container computer">
            <div className="mainserv">
              <Link to={'/webservices'}>
              <div className="servimg">
  <img src={servimg1} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead '>Development</h3>
    <p className='ads_sub '>Our developers ensure seamless cross-device user experiences from backend to frontend.</p>
  </div>
</div>
              </Link>
       
<div className="servimg2">
<Link to={'/digitalmarketingservices'}>
  <img src={servimg2} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>Digital Marketing</h3>
    <span>Do you want traffic? We steer your digital growth using rock-solid, data-driven strategies.</span>
  </div>
  </Link>
</div>

            </div>
                  <div className="mainserv">
                  <Link to={'/brandingservices'}>
                  <div className="servimg2">
  <img src={servimg4} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>Branding</h3>
    <span>Beyond a logo and colors, we'll shape a unique brand identity, assets, and guidelines for consistent touchpoints.</span>
  </div>
</div>
</Link>
            <div className="servimg">
            <Link to={'/al&mlservices'}>
  <img src={servimg3} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>Artificial Intelligence & Machine learning</h3>
    <span>We unleash the power of AI as a seamless service, enhancing your digital experience.</span>
  </div>
  </Link>
</div>

            </div>
            <div className="mainserv">
            <div className="servimg">
            <Link to={'/al&mlservices'}>
  <img src={servimg5} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>PTAAS</h3>
    <span>Drive business growth through cutting-edge Product Team as a Service.</span>
  </div>
  </Link>
</div>
<div className="servimg2">
<Link to={'/designservices'}>
  <img src={servimg6} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>Design</h3>
    <span>We blend aesthetics and behavioral science to create engaging digital products for modern brands.</span>
  </div>
  </Link>
</div>
            </div>

            <div className="mainserv">
                  <div className="servimg2">
                  <Link to={'/erpservices'}>
  <img src={servimg8} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>ERP, CRM & cms development</h3>
    <span>Custom solutions, streamlinedopera-
    tions, enhanced customer relationships, efficient content management.</span>
  </div>
  </Link>
</div>
            <div className="servimg">
            <Link to={'/dataanalyticservices'}>
  <img src={servimg7} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>Data analytics & Consulting</h3>
    <span>Actionable insights, data-driven decisions, advanced analytics, optimized business performance.</span>
  </div>
  </Link>
</div>

            </div>
        </div>

        <div className="container mobileservice">
            <div className="mainserv">
            <div className="servimg">
  <img src={landingimg1} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>Development</h3>
    <span>Our developers ensure seamless cross-device user experiences from backend to frontend.</span>
  </div>
</div>
<div className="servimg2">
  <img src={Landingimg2} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>Digital Marketing</h3>
    <span>Do you want traffic? We steer your digital growth using rock-solid, data-driven strategies.</span>
  </div>
</div>
            </div>
                  <div className="mainserv">
                  <div className="servimg2">
  <img src={Landingimg3} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'></h3>
    <span>Our developers ensure seamless cross-device user experiences from backend to frontend.</span>
  </div>
</div>
            <div className="servimg">
  <img src={Landingimg4} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>Branding</h3>
    <span>Beyond a logo and colors, we'll shape a unique brand identity, assets, and guidelines for consistent touchpoints.</span>
  </div>
</div>

            </div>
            <div className="mainserv">
            <div className="servimg">
  <img src={Landingimg5} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>Artificial Intelligence & Machine learning</h3>
    <span>We unleash the power of AI as a seamless service, enhancing your digital experience.</span>
  </div>
</div>
<div className="servimg2">
  <img src={Landingimg6} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>PTaaS</h3>
    <span>Drive business growth through cutting-edge Product Team as a Service.</span>
  </div>
</div>
            </div>

            <div className="mainserv">
                  <div className="servimg2">
  <img src={Landingimg7} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>Design</h3>
    <span>We blend aesthetics and behavioral science to create engaging digital products for modern brands.</span>
  </div>
</div>
            <div className="servimg">
  <img src={Landingimg8} alt="" className="image" />
  <div className="text-overlay">
    <h3 className='servimghead'>Data analytics & Consulting</h3>
    <span>Actionable insights, data-driven decisions, advanced analytics, optimized business performance.</span>
  </div>
</div>

            </div>
        </div>

        <div className="technologies-section container">
      <h2>Our Technologies</h2>
      <p style={{fontWeight:'600'}}>Leveraging Modern Tools, Focused on Your Success</p>
      <div className="technologies-grid">
        {technologies.map((tech, index) => (
          <div key={index} className="technology-item">
            <img src={tech.icon} alt={`${tech.name} icon`} />
            <span>{tech.name}</span>
          </div>
        ))}
      </div>
    </div>


    </div>
  )
}

export default ServiceCont