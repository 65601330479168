import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar'
import Contactads from '../../components/ads3/Contactads'
import Footer from '../../components/footer/Footer'
import ErpHero from '../../components/erp/ErpHero'
import ErpCarousel from '../../components/erp/ErpCarousel'

const ErpServices = () => {
  useEffect (() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
  <div className="erpservice">
    <Navbar/>
    {/* <MainServiceHero/> */}
   
    <ErpHero/>
    <Contactads/>

<Footer/>
  </div>
  )
}

export default ErpServices