import React from 'react'
import './servicecomp.css';


const ServiceComp = () => {
  return (
   <div className="servicecomp">
    <div className="container">
        <div className='sercomp'>
          <div>
          <h2 className='mainheadser'>Services</h2>
          <h3 className='sndheadserv'>Experience-infused innovations for Holistic Solutions</h3>
          </div>
        
 <span className='thirdheadserv'>Our holistic solutions blend extensive experience with innovative approaches, 
    ensuring comprehensive, 
    effective strategies tailored to your unique needs and goals.</span>
        </div>
  
    </div>
   </div>
  )
}

export default ServiceComp