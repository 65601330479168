import React from 'react'
import './mainservicehero.css';
import msh1 from '../../assets/msh1.png'
import msh2 from '../../assets/msh2.png'
import msh3 from '../../assets/msh3.png'
import msh4 from '../../assets/msh4.png'
import MainServiceCarousel from '../WebServiceCarousel/MainServiceCarousel';

import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from 'react-router-dom';


const MainServiceHero = () => {
  return (
    <div className="mainservicehero">
        <div className="Web" >
            <div className="msh">
            <div className='msleft'>
                <div className="msleftfirst">
                    <img   src={msh1} alt="main" />
                    <div className="overlay">
        <h1>Serving Up Your Perfect Web Solutions</h1>
        <p>We provide unique identity to differentiate and build customer loyalty</p>
    </div>
                </div>
                <div className="msleftsecond">
                     <div className="image-container">
        <img src={msh2} alt="" />
        <div className="overlay1">
            <h3>Frontend Development</h3>
        
        </div>
    </div>
    <div className="image-container">
        <img src={msh3} alt="" />
        <div className="overlay1">
            <h3>Mobile App Development</h3>
       
        </div>
    </div>
                </div>
             </div>
             <div className="msright">
    <div className="image-container">
        <img src={msh4} alt="" />
   
        
            <div className="overlay2">
            <h1>Technology Consulting</h1>
           
            <Link to="/contact" className="overlay2btn">
    Let's talk <FaRegArrowAltCircleRight className="btnicn" />
  </Link>
        </div>
  
    </div>
</div>
            </div>
       
        </div>
        <div className="container">
            <h1 style={{fontWeight:600}} >Web & App Development <span style={{color:'grey'}} > Services</span></h1>
          
        </div>
        <MainServiceCarousel/>
    </div>
  )
}

export default MainServiceHero