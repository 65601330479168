import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar'
import Digitalmarketinghero from '../../components/digitalmarketing/Digitalmarketinghero'
import Contactads from '../../components/ads3/Contactads'
import Footer from '../../components/footer/Footer'

const DigitalMarketingServices = () => {
  useEffect (() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    
    <div className="digitalmarketing">
      <Navbar/>
      <Digitalmarketinghero/>
      <Contactads/>
      <Footer/>
    </div>
  )
}

export default DigitalMarketingServices