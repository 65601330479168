
import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import AOS from 'aos'
import 'aos/dist/aos.css'
// import './mainservicecarousel.css';

import ptaasslider1 from '../../assets/ptaas1.png'
import ptaasslider2 from '../../assets/ptaas2.png'
import ptaasslider3 from '../../assets/ptaas3.png'
import ptaasslider4 from '../../assets/ptaas4.png'
import ptaasslider5 from '../../assets/ptaas5.png'
import ptaasslider6 from '../../assets/ptaas6.png'
import ptaasslider7 from '../../assets/ptaas7.png'
import ptaasslider8 from '../../assets/ptaas8.png'
import ptaasslider9 from '../../assets/ptaas9.png'




import { FreeMode, Mousewheel, Pagination } from 'swiper/modules';
const  PtaasCarousel = () => {
    useEffect(()=>{
        AOS.init({duration:1000})
          },[])
  return (
      <div className="Container" style={{marginTop:'2rem'}}>
    <>
         <Swiper
           slidesPerView={1.6}
           spaceBetween={20}
           freeMode={true}
           pagination={{
             clickable: true,
           }}
           mousewheel={true}
           modules={[FreeMode, Pagination,Mousewheel]}
           className="mySwiper"
           breakpoints={{
             640: { // for screens >= 640px
               slidesPerView: 2.5,
               spaceBetween: 20,
             },
             768: { // for screens >= 768px
               slidesPerView: 3,
               spaceBetween: 30,
             },
             1024: { // for screens >= 1024px
              slidesPerView: 3.5,
               spaceBetween: 30,
             },
           }}
         >
           <SwiperSlide className='slid' >
             <div className='slidimgsec'>
             <img className='slidimg'  src={ptaasslider1} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'white'}}>Growth Marketing</h3>
    <p  style={{color:'white'}}>We use data-driven marketing strategies to grow the user base of digital products.</p>
  </div>
             </div>
    
             </SwiperSlide>
           <SwiperSlide >   <div >
             <img  src={ptaasslider2} alt="" />
             
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Branding & Marketing</h3>
    <p style={{color:'white'}}>We create and execute branding and marketing campaigns to raise awareness of digital products and drive traffic to the website.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={ptaasslider3} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'white'}}>Maintenance & Support</h3>
    <p  style={{color:'white'}}>We provide ongoing maintenance and support for digital products to ensure they are always up and running.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={ptaasslider4} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'white'}}>Testing & MVP</h3>
    <p  style={{color:'white'}}>We test digital products with users to identify and fix bugs, and then release a minimum viable product (MVP) to the market.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={ptaasslider5} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Technology development</h3>
    <p style={{color:'white'}}>We develop digital products using cutting-edge technologies to ensure they are scalable and reliable.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={ptaasslider6} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'black'}}>Prototyping</h3>
    <p  style={{color:'black'}}>We create prototypes of digital products to test ideas and get feedback from users</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={ptaasslider7} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'white'}}>Idea Validation</h3>
    <p style={{color:'white'}}>We help businesses validate their ideas for digital products by conducting market research, user interviews, and competitive analysis.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={ptaasslider8} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'black'}}>Product Research</h3>
    <p style={{color:'black'}}>We conduct in-depth research on the market and competition to identify opportunities for new digital products.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={ptaasslider9} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'black'}}>UX Research & Design</h3>
    <p style={{color:'black'}}>We design user-friendly and intuitive interfaces for digital products that meet the needs of users.</p>
  </div>
             </div></SwiperSlide>
         </Swiper>
       </>
     </div>
  )
}

export default PtaasCarousel