

import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import AOS from 'aos'
import 'aos/dist/aos.css'
// import './mainservicecarousel.css';

import brnadingservice1 from '../../assets/brandingservice1.png'
import brnadingservice2 from '../../assets/brandingservice2.png'
import brnadingservice3 from '../../assets/brandingservice3.png'
import brnadingservice4 from '../../assets/brandingservice4.png'
import brnadingservice5 from '../../assets/brandingservice5.png'
import brnadingservice6 from '../../assets/brandingservice6.png'
import brnadingservice7 from '../../assets/brandingservice7.png'



import { FreeMode, Pagination ,Mousewheel} from 'swiper/modules';
const BrandingCarousel = () => {
    useEffect(()=>{
        AOS.init({duration:1000})
          },[])
  return (
    <div className='Container' style={{marginTop:'2rem'}}>
    <>
         <Swiper
           slidesPerView={1.5}
           spaceBetween={30}
           freeMode={true}
           pagination={{
             clickable: true,
           }}
           mousewheel={true}
           modules={[FreeMode, Pagination,Mousewheel]}
           className="mySwiper"
           breakpoints={{
             640: { // for screens >= 640px
               slidesPerView: 2,
               spaceBetween: 20,
             },
             768: { // for screens >= 768px
               slidesPerView: 3,
               spaceBetween: 30,
             },
             1024: { // for screens >= 1024px
              slidesPerView: 3.5,
               spaceBetween: 40,
             },
           }}
         >
           <SwiperSlide className='slid' >
             <div className='slidimgsec'>
             <img className='slidimg'  src={brnadingservice1} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Content Strategy</h3>
    <p style={{color:'white'}} >Develop and execute content plans to enhance brand engagement and visibility.</p>
  </div>
             </div>
    
             </SwiperSlide>
           <SwiperSlide >   <div >
             <img  src={brnadingservice2} alt="" />
             
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Market Research</h3>
    <p style={{color:'white'}}>Analyze market trends and consumer insights to shape effective branding strategies.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={brnadingservice3} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'  style={{color:'white'}}>Brand Experiences</h3>
    <p  style={{color:'white'}}>Formulating rules for consistent brand representation across touchpoints.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={brnadingservice4} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'>Brand Identity</h3>
    <p>Develop and implement cohesive brand identity for impactful market presence</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={brnadingservice5} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice' style={{color:'white'}}>Visual Identity</h3>
    <p style={{color:'white'}}>Creating eye-catching visuals that embody your brand's essence.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide> <div >
             <img  src={brnadingservice6} alt="" />
             <div className="text-overlayservice">
    <h3 className='servimgheadservice'>Verbal Identity</h3>
    <p>Crafting words that resonate, establishing a memorable brand voice.</p>
  </div>
             </div></SwiperSlide>
             <SwiperSlide >   <div >
             <img  src={brnadingservice7} alt="" />
             <div className="text-overlayservice" >
    <h3 className='servimgheadservice' style={{color:'white'}}>Brand Strategy</h3>
    <p style={{color:'white'}}>Crafting brand blueprints for success and resonance, along with a precise nomenclature</p>
  </div>
             </div></SwiperSlide>
         </Swiper>
       </>
     </div>
  )
}

export default BrandingCarousel