import React, { useEffect } from 'react'
import MainServiceHero from '../../components/webservicehero/MainServiceHero'
import Navbar from '../../components/Navbar'
import Contactads from '../../components/ads3/Contactads'
import Footer from '../../components/footer/Footer'


const MainService = () => {
  useEffect (() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
   <div className="mainservice">
    <Navbar/>
    <MainServiceHero/>
    <Contactads/>

<Footer/>
   </div>
  )
}

export default MainService