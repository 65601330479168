import React from 'react'
import './blogcontent.css';
import blogimg1 from '../../assets/Rectangle 62319.png'
import blogimg2 from '../../assets/Rectangle 62320.png'
import blogimg3 from '../../assets/Rectangle 62321.png'


const BlogContent = () => {
  return (
   <div className="blogcontent">
    <div className="container">
      <div className='bloghead'>
      <h3 className='mainheadser'>Careers</h3>
      </div>
      <div className='mainblog' >
      <div className="blogcnt">
        <img src={blogimg1} alt="" />
        <div className='smalldescr'>
            <span>123 mins read </span>
            <span>5 April 2024</span>
        </div>
        <h3 className='blogheading'>Predictive Analytics for E-commerce: 10 Ways to Boost Your Business</h3>
      </div>
      <div className="blogcnt">
        <img src={blogimg2} alt="" />
        <div className='smalldescr'>
            <span>123 mins read </span>
            <span>5 April 2024</span>
        </div>
        <h3 className='blogheading'>Predictive Analytics for E-commerce: 10 Ways to Boost Your Business</h3>
      </div>
      <div className="blogcnt">
        <img src={blogimg3} alt="" />
        <div className='smalldescr'>
            <span>123 mins read </span>
            <span>5 April 2024</span>
        </div>
        <h3 className='blogheading'>Predictive Analytics for E-commerce: 10 Ways to Boost Your Business</h3>
      </div>
      </div>
    </div>
   </div>
  )
}

export default BlogContent