
import React from 'react'
// import './mainservicehero.css';
import brandinghero1 from '../../assets/brandinghero1.png'
import brandinghero2 from '../../assets/brandinghero2.png'
import brandinghero3 from '../../assets/brandinghero3.png'
// import brandinghero4 from '../../assets/bra'
import msh1 from '../../assets/msh1.png'

import { FaRegArrowAltCircleRight } from "react-icons/fa";
import './branding.css'
import BrandingCarousel from './BrandingCarousel'
import { Link } from 'react-router-dom'
const BrandingHero = () => {
  return (
    <div className="mainservicehero">
    <div className="Web">
        <div className="msh">
        <div className='msleft'>
            <div className="msleftfirst">
                <img  src={msh1} alt="" />
                <div className="overlay">
    <h1>Craft Your Brand Future</h1>
    <p>We provide unique identity to differentiate and build customer loyalty</p>
</div>
            </div>
            <div className="msleftsecond">
                {/* <img src={msh2} alt="" />
                
                <img src={msh3} alt="" /> */}
                 <div className="image-container">
    <img src={brandinghero2} alt="" />
    <div className="overlay1">
        <h3>Custom</h3>
    
    </div>
</div>
<div className="image-container">
    <img src={brandinghero3} alt="" />
    <div className="overlay1">
        <h3>Popular CMS Platforms</h3>
   
    </div>
</div>
            </div>
         </div>
         <div className="msright">
<div className="image-container">
    <img src={brandinghero1} alt="" />
    <div className="overlay2">
        <h1  style={{color:'black'}}>Analytics & Reporting</h1>
      
        <Link to="/contact" className="overlay2btn">
    Let's talk <FaRegArrowAltCircleRight className="btnicn" />
  </Link>
    </div>
</div>
</div>
        </div>
   
    </div>
    <div className="container">
        <h1 style={{fontWeight:600}}>Branding Services <span style={{color:'blue'}} ></span></h1>
    
    </div>
    <BrandingCarousel/>

    <div className="branding-strategy">
            <div className="intro-section">
                <h1>How Artek's Origami Branding Strategy Helps Increase Sales</h1>
                <p>
                    Artek's Origami method is designed to create a strong, impactful brand that resonates with your target audience, which in turn helps drive sales.
                    Here's how each step of our strategy contributes to increasing sales:
                </p>
            </div>
            <div className="steps-grid">
                <div className="step-box communicate">
                    <h2>1. Communicate with the Client</h2>
                    <p>
                        By thoroughly understanding your vision and mission, we ensure that your brand message is clear and compelling. A well-defined vision helps build trust and credibility with your audience, making them more likely to choose your brand.
                    </p>
                </div>
                <div className="step-box research">
                    <h2>2. Research</h2>
                    <p>
                        Detailed research allows us to understand the market and your competition. By knowing what sets your brand apart and addressing the specific needs and desires of your customers, we can position your brand more effectively, leading to higher conversion rates.
                    </p>
                </div>
                <div className="step-box identify">
                    <h2>3. Identify Target Customers</h2>
                    <p>
                        By identifying and focusing on your most potential customers, we ensure that your marketing efforts are targeted and efficient. Reaching the right audience increases the likelihood of conversions and reduces wasted marketing spend.
                    </p>
                </div>
                <div className="step-box define">
                    <h2>4. Define Psychographics and Personas</h2>
                    <p>
                        Understanding the psychographics and personas of your audience allows us to tailor your brand’s messaging and positioning to their preferences and behaviors. This personalized approach enhances engagement and encourages customers to make a purchase.
                    </p>
                </div>
                <div className="step-box positioning">
                    <h2 style={{color:'black'}}>5. Brand Positioning</h2>
                    <p style={{color:'black'}}>
                        Effective brand positioning differentiates your brand in the market, making it stand out to your target customers. A strong, unique position within your customers' minds ensures engagement with your brand’s offerings, driving sales.
                    </p>
                </div>
                <div className="step-box identity">
                    <h2>6. Brand Identity</h2>
                    <p>
                        A compelling visual identity that resonates with your target audience helps in creating an emotional connection. Strong brand recognition and appeal can influence purchasing decisions and foster brand loyalty.
                    </p>
                </div>
                <div className="step-box strategy">
                    <h2 style={{color:'black'}}>7. Brand Strategy</h2>
                    <p style={{color:'black'}}>
                        A comprehensive brand strategy ensures consistent messaging and experience across all touchpoints. Consistency builds trust and reliability, encouraging repeat purchases and customer loyalty.
                    </p>
                </div>
            </div>
        </div>

        <section className="container">
      <h2 className="expertise-heading">Our Expertise</h2>
      <div className="expertise-cards">
        <div className="expertise-card">
          <h3 className="expertise-title">FMCG & Retail</h3>
          <p className="expertise-description">
            Our expertise in FMCG & Retail branding brings impactful visual narratives. With a track record of success, we elevate brand presence and consumer connections.
          </p>
        </div>
        <div className="expertise-card">
          <h3 className="expertise-title">Fashion</h3>
          <p className="expertise-description">
            Fashion branding is our domain. With a record of success, we curate brand stories that capture style, resonate with audiences, and elevate the fashion industry.
          </p>
        </div>
        <div className="expertise-card">
          <h3 className="expertise-title">Health & Wellness</h3>
          <p className="expertise-description">
            Health & Wellness branding is our strength. With extensive experience, we convey the essence of well-being, fostering connections and trust in the industry.
          </p>
        </div>
      </div>
    </section>
</div>
  )
}

export default BrandingHero