import React from 'react'
// import './mainservicehero.css';
import msh1 from '../../assets/msh1.png'
import msh2 from '../../assets/msh2.png'
import msh3 from '../../assets/msh3.png'
import msh4 from '../../assets/msh4.png'
import MainServiceCarousel from '../WebServiceCarousel/MainServiceCarousel';
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import ErpCarousel from './ErpCarousel'
import erphero1 from '../../assets/erphero1.png'
import { Link } from 'react-router-dom'
const ErpHero = () => {
  return (
    <div className="mainservicehero">
        <div className="Web">
            <div className="msh">
            <div className='msleft'>
                <div className="msleftfirst">
                    <img  src={msh1} alt="" />
                    <div className="overlay">
        <h1>Serving Up Your Perfect Web Solutions</h1>
        <p>Just like gourmet meal, our web development services are tailored to your exact tastes and needs, ensuring a deliciously effective online presence.</p>
    </div>
                </div>
                <div className="msleftsecond">
                    {/* <img src={msh2} alt="" />
                    
                    <img src={msh3} alt="" /> */}
                     <div className="image-container">
        <img src={msh2} alt="" />
        <div className="overlay1">
            <h3>Custom</h3>
        
        </div>
    </div>
    <div className="image-container">
        <img src={msh3} alt="" />
        <div className="overlay1">
            <h3>Popular CMS Platforms</h3>
       
        </div>
    </div>
                </div>
             </div>
             <div className="msright">
    <div className="image-container">
        <img src={erphero1} alt="" />
        <div className="overlay2">
            <h1>Analytics & Reporting</h1>
         
            <Link to="/contact" className="overlay2btn">
    Let's talk <FaRegArrowAltCircleRight className="btnicn" />
  </Link>
        </div>
    </div>
</div>
            </div>
       
        </div>
        <div className="container">
            <h1 style={{fontWeight:600}}>ERP/CRM/CSM Development <span style={{color:'blue'}} > Services</span></h1>
        
        </div>
        <ErpCarousel/>
    </div>
  )
}

export default ErpHero