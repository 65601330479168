import React from 'react'
import Navbar from '../../components/Navbar'
import Contactads from '../../components/ads3/Contactads'
import Footer from '../../components/footer/Footer'
import ServiceComp from '../../components/servicecomp/ServiceComp'
import './services.css';
import ServiceCont from '../../components/servicecontent/ServiceCont'
const Services = () => {
  return (
    <div className="ho">
  <Navbar />
  <ServiceComp/>
  <ServiceCont/>
  <Contactads/>
  <Footer/>

    </div>
 
  )
}

export default Services