import React, { useEffect } from 'react'
import './contactad.css'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'


const Contactads = () => {
  useEffect(()=>{
    AOS.init({duration:1000})
      },[])
  return (

<div className="container">
  <div className='cntads'>
  <span className='firsthead'>Got An Idea?</span>
  <span className='secndhead'>Let's craft solution </span>
  <span className='secndhead'>together</span>
  <Link to={'/contact'}  >
  <button  className='btnfoot'>Get in Touch</button>
  </Link>
 
  </div>

</div>
  )
}

export default Contactads