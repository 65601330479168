import React from 'react'
// import './mainservicehero.css';
import msh1 from '../../assets/msh1.png'
import ptaashero1 from '../../assets/ptaashero1.png'
import ptaashero2 from '../../assets/ptaashero2.png'

import ptaashero3 from '../../assets/ptaashero3.png'


import { FaRegArrowAltCircleRight } from "react-icons/fa";

import erphero1 from '../../assets/erphero1.png'
import PtaasCarousel from './PtaasCarousel'
import { Link } from 'react-router-dom'
const PtaasHero = () => {
  return (
    <div className="mainservicehero">
        <div className="Web">
            <div className="msh">
            <div className='msleft'>
                <div className="msleftfirst">
                    <img  src={msh1} alt="" />
                    <div className="overlay">
        <h1>Product Team As a Service</h1>
        <p>We turn your Vision into Reality with our Product Team - as a Service</p>
    </div>
                </div>
                <div className="msleftsecond">
                    {/* <img src={msh2} alt="" />
                    
                    <img src={msh3} alt="" /> */}
                     <div className="image-container">
        <img src={ptaashero3} alt="" />
        <div className="overlay1">
            <h3>UX Research & Design</h3>
        
        </div>
    </div>
    <div className="image-container">
        <img src={ptaashero2} alt="" />
        <div className="overlay1">
            <h3>Growth Marketing</h3>
       
        </div>
    </div>
                </div>
             </div>
             <div className="msright">
    <div className="image-container">
        <img src={ptaashero1} alt="" />
        <div className="overlay2">
            <h1>Idea Validation</h1>
           
            <Link to="/contact" className="overlay2btn">
    Let's talk <FaRegArrowAltCircleRight className="btnicn" />
  </Link>
        </div>
    </div>
</div>
            </div>
       
        </div>
        <div className="container">
            <h1 style={{fontWeight:600}}>Product Team as a Service </h1>
        
        </div>
        <PtaasCarousel/>
    </div>
  )
}

export default PtaasHero