import React from 'react'
import './careershead.css'
import img1 from '../../assets/Rectangle 89.png';
import img2 from '../../assets/Rectangle 90.png';
import img3 from '../../assets/Rectangle 91.png';

const CareersHead = () => {
  return (
  <div className="careershead">
    <div className="container">
    <div className='careercomp'>
        <div>
        <h3 className='mainheadser'>Careers</h3>
        <h3 className='sndheadserv'>Transform your potential into innovation with us.</h3>
        </div>
        
 <h3 className='thirdheadserv'>At Artek, we believe in turning ideas into reality. 
    Join our team to work on cutting-edge projects, collaborate with passionate professionals, and push the boundaries of technology.
     Let's transform your potential into groundbreaking innovations together.</h3>
        </div>
        <div className="imgsec">
          <div className='firstimgsec'>
            <img className='firstimg'  src={img1} alt="" />
    
          </div>
          <div className="scndimgsec">
          <img  className='scndimg1' src={img3} alt="" />
          <img  className='scndimg' src={img2} alt="" />
        
     
          </div>
        </div>
    </div>
  </div>
  )
}

export default CareersHead