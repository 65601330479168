import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar'
import Contactads from '../../components/ads3/Contactads'
import Footer from '../../components/footer/Footer'
import PtaasHero from '../../components/ptaas/PtaasHero'


const PtaasServices = () => {
  useEffect (() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div className="ptaas">
      <Navbar/>
      <PtaasHero/>
      <Contactads/>
      <Footer/>
    </div>
  )
}

export default PtaasServices