import React from 'react'
// import './mainservicehero.css';
import msh1 from '../../assets/msh1.png'
import digitalhero1 from '../../assets/digitalhero1.png'
import digitalhero2 from '../../assets/digitalhero2.png'

import digitalhero3 from '../../assets/digitalhero3.png'

import { Link } from 'react-router-dom';
import { FaRegArrowAltCircleRight } from "react-icons/fa";


import DigitalMarketingCarousel from './DigitalMarketingCarousel'
const Digitalmarketinghero = () => {
  return (
    <div className="mainservicehero">
        <div className="Web">
            <div className="msh">
            <div className='msleft'>
                <div className="msleftfirst">
                    <img  src={msh1} alt="" />
                    <div className="overlay">
        <h1>Get a Taste of Top - Notch Digital Marketing</h1>
        <p>We provide unique identity to differentiate and build customer loyalty</p>
    </div>
                </div>
                <div className="msleftsecond">
                    {/* <img src={msh2} alt="" />
                    
                    <img src={msh3} alt="" /> */}
                     <div className="image-container">
        <img src={digitalhero1} alt="" />
        <div className="overlay1">
            <h3>Social Media Marketing </h3>
        
        </div>
    </div>
    <div className="image-container">
        <img src={digitalhero2} alt="" />
        <div className="overlay1">
            <h3>PR
            Marketing</h3>
       
        </div>
    </div>
                </div>
             </div>
             <div className="msright">
    <div className="image-container">
        <img src={digitalhero3} alt="" />
        <div className="overlay2">
            <h1 style={{textAlign:'center' ,marginRight:'35px'}}> Performance Marketing (PPC)</h1>
            <Link to="/contact" className="overlay2btn">
    Let's talk <FaRegArrowAltCircleRight className="btnicn" />
  </Link>
        </div>
    </div>
</div>
            </div>
       
        </div>
        <div className="container">
            <h1 style={{fontWeight:'600'}} >Digital Marketing Services</h1>
        
        </div>
        <DigitalMarketingCarousel/>
    </div>
  )
}

export default Digitalmarketinghero