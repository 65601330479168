
import Navbar from '../../components/Navbar'
import BrandingHero from '../../components/branding/BrandingHero'
import Contactads from '../../components/ads3/Contactads'
import Footer from '../../components/footer/Footer'
import { useEffect } from 'react'

const BrandingService = () => {
  useEffect (() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div className="brandingservices">
       <div className="erpservice">
    <Navbar/>

   
   <BrandingHero/>
    <Contactads/>

<Footer/>
  </div>
    </div>
  )
}

export default BrandingService