import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/footer/Footer'
import DesignHero from '../../components/design/DesignHero'

const DesingService = () => {
  useEffect (() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
   <div className="design">
    <Navbar/>
    <DesignHero/>
    <Footer/>
   </div>
  )
}

export default DesingService