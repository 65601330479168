import React from 'react'
// import './mainservicehero.css';
import msh1 from '../../assets/msh1.png'
import designhero1 from '../../assets/designhero1.png'
import designhero2 from '../../assets/designhero2.png'
import designhero3 from '../../assets/designhero4.png'

import { Link } from 'react-router-dom';

import { FaRegArrowAltCircleRight } from "react-icons/fa";


import DesignCarousel from './DesignCarousel'
const DesignHero = () => {
  return (
    <div className="mainservicehero">
        <div className="Web">
            <div className="msh">
            <div className='msleft'>
                <div className="msleftfirst">
                    <img  src={msh1} alt="" />
                    <div className="overlay">
        <h1>Bring Life to Your Idea Through Impactful Designs</h1>
        <p>We provide unique identity to differentiate and build customer loyalty</p>
    </div>
                </div>
                <div className="msleftsecond">
                    {/* <img src={msh2} alt="" />
                    
                    <img src={msh3} alt="" /> */}
                     <div className="image-container">
        <img src={designhero2} alt="" />
        <div className="overlay1">
            <h3>Design Systems</h3>
        
        </div>
    </div>
    <div className="image-container">
        <img src={designhero1} alt="" />
        <div className="overlay1">
            <h3>CX, UX & BX Design</h3>
       
        </div>
    </div>
                </div>
             </div>
             <div className="msright">
    <div className="image-container">
        <img src={designhero3 } alt="" />
        <div className="overlay2">
            <h1 style={{color:'black'}} >Web Design</h1>
        
            <Link to="/contact" className="overlay2btn">
    Let's talk <FaRegArrowAltCircleRight className="btnicn" />
  </Link>
        </div>
    </div>
</div>
            </div>
       
        </div>
        <div className="container">
            <h1 style={{fontWeight:600}}>Design Services</h1>
        
        </div>
        <DesignCarousel/>
    </div>
  )
}

export default DesignHero