import React from 'react'
import './hero.css'
import msh1 from '../../assets/msh1.png'
import msh2 from '../../assets/lan1.png'
import msh3 from '../../assets/msh3.png'
import msh4 from '../../assets/msh4.png'
import landhero1 from '../../assets/landhero1.png'
import landhero2 from '../../assets/lan2.png'


import { FaRegArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
// import {AiOutlineSearch} from 'react-icons/ai'
const Hero = () => {
  return (
    <div className='mainservicehero'>
  <div className="Web" >
            <div className="msh">
            <div className='msleft'>
                <div className="msleftfirst">
                    <img  src={msh1} alt="" />
                    <div className="overlay">
        <h1>THE GLOBAL FULL STACK SOLUTIONIST</h1>
        <p>We harness the latest technologies to drive innovation, streamline operations, and help you scale with confidence in today’s competitive market</p>
    </div>
                </div>
                <div className="msleftsecond">
                     <div className="image-container">
        <img src={msh2} alt="" />
        <div className="overlay1">
            <h3>Elevate Your Online Precence</h3>
        
        </div>
    </div>
    <div className="image-container">
        <img src={landhero2} alt="" />
        <div className="overlay1">
            <h3>Data Driven Growth</h3>
       
        </div>
    </div>
                </div>
             </div>
             <div className="msright">
    <div className="image-container">
        <img src={landhero1} alt="" />
        <div className="overlay2">
            <span style={{fontSize:'2rem'}}>Technology Consulting</span>
           
            <Link to="/contact" className="overlay2btn">
    Let's talk <FaRegArrowAltCircleRight className="btnicn" />
  </Link>
  
        </div>
    </div>
</div>
            </div>
       
        </div>

        <div className="animation-wrapper">
  <div className="animation">
    <span>Global Full Stack Digital Solutionist</span>
    <span>Global Full Stack Digital Solutionist</span>
    <span>Global Full Stack Digital Solutionist</span>
    <span>Global Full Stack Digital Solutionist</span>
    <span>Digital Solutionist</span>
    <span>Digital Solutionist</span>
    <span>Global Full Stack Digital Solutionist</span>
    <span>Global Full Stack Digital Solutionist</span>

    <span>Digital Solutionist</span>
    <span>Global Full Stack Digital Solutionist</span>

    <span>Digital Solutionist</span>
    <span>Global Full Stack Digital Solutionist</span>

  </div>
</div>




</div>
  )
}

export default Hero